(function ($) {

  Drupal.behaviors.videoLightBoxBehavior = {
    attach: function(){
      $('.field--videos').once('videoLightBox', function(){
        var $video = $(this).find('.field--video');
        $video.hide(0);

        $(this).click(function() {
         console.log("video click");
          var videoPlayer = videojs($video.find('.video-js').attr('id').trim());
          $.fancybox.open([$video], {afterClose: function() { videoPlayer.currentTime(0); videoPlayer.pause(); }});

          setTimeout(function() {
            videoPlayer.play();
          }, 200);
        });
      });
    }
  };
   Drupal.behaviors.menuToggleBehavior = {
     attach: function(context, settings){
       $('.menu-toggle', context).once('menuToggle', function(){
        var $menuToggle = $('.menu-toggle');
        var $menu = $(".main-menu");
        $menu.slideUp(0);
        $menuToggle.click(function (e){
          e.preventDefault();
          $('body').toggleClass('menu-open');
          $menu.slideToggle(300);
        });
      });
     }
   };



   Drupal.behaviors.heroSliderBehavior = {
     attach: function(context, settings){
       $('.hero-slider', context).once('heroSlider', function(){
        var $slider = $(this);
        var $slides = $(this).find("img").parent();
        var $nextPager = $('<a href="#" class="hero-slider__nav hero-slider__nav--next">Next</a>');
        var $previousPager = $('<a href="#" class="hero-slider__nav hero-slider__nav--previous">Previous</a>');
        $slider.append([$previousPager, $nextPager]);

        $slides.filter(':gt(0)').fadeTo(0, 0);
        var slideIndex = 0;
        var slideCount = $slides.length;

        $nextPager.click(function(event) {
          event.preventDefault();
          slideIndex++;
          slide();
        });

        $previousPager.click(function(event) {
          event.preventDefault();
          slideIndex--;
          slide();
        });

        function slide() {
          if(slideIndex<0) {
            slideIndex = slideCount - 1;
          }
          if (slideIndex >= slideCount) {
            slideIndex = 0;
          }

          $slides.stop().fadeTo(300, 0).eq(slideIndex).stop().fadeTo(300, 1);
        }
      });
    }
  };

  Drupal.behaviors.inputPlaceholderBehavior = {
    attach: function(context, settings){
      if(!Modernizr.placeholder) return false;
      $('.form-type-textfield, .form-type-textarea', context).once('inputPlaceholder', function(){
        var $inputWrapper = $(this),
        $label = $inputWrapper.find('label'),
        $input = $inputWrapper.find('input, textarea'),
        labelText = $label.text();

        $label.remove();
        $input.attr("placeholder", labelText);
      });
    }
  };


Drupal.behaviors.moveSocialShareBehavior = {
  attach: function(context, settings){
    $('.social-share', context).once('moveSocialShare', function(){
     $(this).appendTo($('.pane-page-title h1'));
    });
  }
};



})(jQuery);